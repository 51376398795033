import { ChatbotUiState, UiState, WebUiState } from "@/components/enums/UiState";

import { observable, action, makeObservable } from "mobx";
import {
    Age,
    CancelCall,
    EndOfCallOld,
    EndOfCallRegular,
    Hint,
    Landing,
    Loading,
    Global,
    PostProcessingEntity,
    Mask,
    Tutorial,
    Start,
    ComingSoon,
    PageNotFound,
    ChatStele,
    PrivacyPolicy,
    Imprint,
    I18NEntity,
    ChatBranchOver50,
    Chatbot,
    ComponentChatMessageAiChat,
    ComponentChatMessageTextAi,
    Meta,
} from "types/strapi";
import {
    ChatBranchEntity,
    ComponentChatMessageText,
    ComponentChatMessageImage,
    ComponentChatMessageButtonGroup,
    ComponentChatMessageGoToBranch,
    ComponentChatMessageLink,
    ChatBranchOver50Entity,
    ChatAiBranchEntity,
    ImageProcessingState,
} from "types/chat";
import { trackPage } from "utils/track";
import { SceneChatMessagesDynamicZone } from "@/components/pages/stele/Chat";
import { ComponentChatMessageAiText } from "@/components/chat/ComponentChatMessageAiText";

export class Store {
    locale: string | undefined = undefined;
    locales: string[] = [];
    localeNames: string[] = [];
    i18nData: I18NEntity[] = [];
    postProcessings: PostProcessingEntity[] = [];
    metaLocalized: Meta | undefined = undefined;
    landingLocalized: Landing | undefined = undefined;
    ageLocalized: Age | undefined = undefined;
    cancelCallLocalized: CancelCall | undefined = undefined;
    endOfCallOldLocalized: EndOfCallOld | undefined = undefined;
    endOfCallRegularLocalized: EndOfCallRegular | undefined = undefined;
    hintLocalized: Hint | undefined = undefined;
    loadingLocalized: Loading | undefined = undefined;
    maskLocalized: Mask | undefined = undefined;
    tutorialLocalized: Tutorial | undefined = undefined;
    globalLocalized: Global | undefined = undefined;
    startLocalized: Start | undefined = undefined;
    comingSoonLocalized: ComingSoon | undefined = undefined;
    pageNotFoundLocalized: PageNotFound | undefined = undefined;
    privacyPolicyLocalized: PrivacyPolicy | undefined = undefined;
    imprintLocalized: Imprint | undefined = undefined;
    chatbotLocalized: Chatbot | undefined = undefined;
    chatSteleLocalized: ChatStele | undefined = undefined;
    chatBranches: ChatBranchEntity[] = [];
    chatAiBranches: ChatAiBranchEntity[] = [];
    chatBranchesOver50: ChatBranchOver50Entity[] = [];
    actualChatThread: (
        | ComponentChatMessageText
        | ComponentChatMessageAiText
        | ComponentChatMessageImage
        | ComponentChatMessageLink
        | ComponentChatMessageButtonGroup
        | ComponentChatMessageGoToBranch
    )[] = [];
    actualChatOver50Thread: (
        | ComponentChatMessageText
        | ComponentChatMessageImage
        | ComponentChatMessageLink
        | ComponentChatMessageButtonGroup
        | ComponentChatMessageGoToBranch
    )[] = [];
    chatMessageEnlarged: boolean = false;
    chatProjectionSceneReady: boolean = false;
    chatBackground: string = "";
    chatEffect: string = "";

    renderContent: UiState = UiState.LANDING;
    renderWebContent: WebUiState = WebUiState.LANDING;
    renderChatbotContent: ChatbotUiState = ChatbotUiState.LANDING;

    imagePresentUrl: string | undefined = undefined;
    imageAgedUrl: string | undefined = undefined;
    selfieImageUrl: string | undefined = undefined;
    captureFace: boolean = false;
    over50: boolean = false;
    isLoading: boolean = true;

    buttonsPressed: boolean[] = new Array(501).fill(false);
    chatMessagesScene: SceneChatMessagesDynamicZone[] | undefined = undefined;
    chatOver50MessagesScene: SceneChatMessagesDynamicZone[] | undefined = undefined;
    chatIndexCurrent: number = 0;
    chatOver50IndexCurrent: number = 0;
    chatIndexDelayedCurrent: number | undefined = undefined;
    chatOver50IndexDelayedCurrent: number | undefined = undefined;
    chatIndexTypedCurrent: number | undefined = undefined;
    chatover50indexTypedCurrent: number | undefined = undefined;

    startCountdown: boolean = false;
    chatTimer: boolean = false;

    lastPointerInteraction: Date | undefined = undefined;

    isCancelCallVisible: boolean = false;

    videoTransitionSourceUrl: string = "";
    videoMainSourceUrl: string = "";
    transitionFinished: boolean = false;

    imageProcessingState: ImageProcessingState = {
        positionInQueue: undefined,
        tokenAcquired: false,
        noConnection: false,
        noFaceFound: false,
        waitingToSubmit: false,
        waitingForResult: false,
        processingAborted: false,
        processingComplete: false,
    };

    faceNotDetected: boolean = false;

    isPortrait: boolean = true;

    directLinkFromChatbot: boolean = false;

    isCameraAllowed: boolean = false;

    constructor() {
        makeObservable(this, {
            locale: observable,
            locales: observable,
            localeNames: observable,
            postProcessings: observable,
            imagePresentUrl: observable,
            imageAgedUrl: observable,
            captureFace: observable,
            over50: observable,
            renderContent: observable,
            chatMessagesScene: observable,
            chatIndexCurrent: observable,
            chatOver50IndexCurrent: observable,
            chatIndexDelayedCurrent: observable,
            chatIndexTypedCurrent: observable,
            buttonsPressed: observable,
            startCountdown: observable,
            chatTimer: observable,
            landingLocalized: observable,
            metaLocalized: observable,
            ageLocalized: observable,
            cancelCallLocalized: observable,
            endOfCallOldLocalized: observable,
            endOfCallRegularLocalized: observable,
            hintLocalized: observable,
            loadingLocalized: observable,
            maskLocalized: observable,
            tutorialLocalized: observable,
            globalLocalized: observable,
            startLocalized: observable,
            renderWebContent: observable,
            renderChatbotContent: observable,
            comingSoonLocalized: observable,
            pageNotFoundLocalized: observable,
            privacyPolicyLocalized: observable,
            imprintLocalized: observable,
            chatbotLocalized: observable,
            chatSteleLocalized: observable,
            chatBranches: observable,
            chatAiBranches: observable,
            chatBranchesOver50: observable,
            actualChatThread: observable,
            actualChatOver50Thread: observable,
            chatMessageEnlarged: observable,
            chatProjectionSceneReady: observable,
            chatBackground: observable,
            chatEffect: observable,
            lastPointerInteraction: observable,
            i18nData: observable,
            isCancelCallVisible: observable,
            videoTransitionSourceUrl: observable,
            videoMainSourceUrl: observable,
            transitionFinished: observable,
            isLoading: observable,
            imageProcessingState: observable,
            faceNotDetected: observable,
            isPortrait: observable,
            directLinkFromChatbot: observable,
            isCameraAllowed: observable,
            updateIsCancelCallVisible: action,
            updateLocale: action,
            updateLocales: action,
            updateLocaleNames: action,
            updateMetaLocalized: action,
            updatePostProcessings: action,
            updateImagePresentUrl: action,
            updateImageAgedUrl: action,
            updateCaptureFace: action,
            updateOver50: action,
            updateRenderContent: action,
            updateButtonById: action,
            updateChatMessagesScene: action,
            updateChatOver50MessagesScene: action,
            getButtonPressedById: action,
            updateChatIndexCurrent: action,
            updateChatOver50IndexCurrent: action,
            updateChatIndexDelayedCurrent: action,
            updateChatIndexTypedCurrent: action,
            updateStartCountdown: action,
            updateChatTimer: action,
            updateLandingLocalized: action,
            updateAgeLocalized: action,
            updateCancelCallLocalized: action,
            updateEndOfCallOldLocalized: action,
            updateEndOfCallRegularLocalized: action,
            updateHintLocalized: action,
            updateLoadingLocalized: action,
            updateMaskLocalized: action,
            updateTutorialLocalized: action,
            updateGlobalLocalized: action,
            updateStartLocalized: action,
            updateRenderWebContent: action,
            updateRenderChatbotContent: action,
            updateComingSoonLocalized: action,
            updatePageNotFoundLocalized: action,
            updateChatSteleLocalized: action,
            updateChatBranches: action,
            updateChatBranchesOver50: action,
            updateActualChatThread: action,
            updateActualChatOver50Thread: action,
            updateChatMessageEnlarged: action,
            updateButtonsPressed: action,
            resetAll: action,
            updateChatProjectionSceneReady: action,
            updateLastPointerInteraction: action,
            updatePrivacyPolicyLocalized: action,
            updateImprintLocalized: action,
            updateChatbotLocalized: action,
            updateI18nData: action,
            updateChatBackground: action,
            updateVideoTransitionSourceUrl: action,
            updateVideoMainSourceUrl: action,
            updateTransitionFinished: action,
            updateChatAiBranches: action,
            updateIsLoading: action,
            updateImageProcessingState: action,
            updateFaceNotDetected: action,
            updateIsPortrait: action,
            updateDirectLinkFromChatbot: action,
            updateIsCameraAllowed: action,
        });

        this.updateLocale = this.updateLocale.bind(this);
        this.updateLocales = this.updateLocales.bind(this);
        this.updateLocaleNames = this.updateLocaleNames.bind(this);
        this.updatePostProcessings = this.updatePostProcessings.bind(this);
        this.updateImagePresentUrl = this.updateImagePresentUrl.bind(this);
        this.updateImageAgedUrl = this.updateImageAgedUrl.bind(this);
        this.updateSelfieImageUrl = this.updateSelfieImageUrl.bind(this);
        this.updateCaptureFace = this.updateCaptureFace.bind(this);
        this.updateOver50 = this.updateOver50.bind(this);
        this.updateRenderContent = this.updateRenderContent.bind(this);
        this.updateButtonById = this.updateButtonById.bind(this);
        this.updateChatMessagesScene = this.updateChatMessagesScene.bind(this);
        this.updateChatOver50MessagesScene = this.updateChatOver50MessagesScene.bind(this);
        this.getButtonPressedById = this.getButtonPressedById.bind(this);
        this.updateChatIndexCurrent = this.updateChatIndexCurrent.bind(this);
        this.updateChatOver50IndexCurrent = this.updateChatOver50IndexCurrent.bind(this);
        this.updateChatIndexDelayedCurrent = this.updateChatIndexDelayedCurrent.bind(this);
        this.updateChatIndexTypedCurrent = this.updateChatIndexTypedCurrent.bind(this);
        this.updateStartCountdown = this.updateStartCountdown.bind(this);
        this.updateChatTimer = this.updateChatTimer.bind(this);
        this.updateLandingLocalized = this.updateLandingLocalized.bind(this);
        this.updateMetaLocalized = this.updateMetaLocalized.bind(this);
        this.updateAgeLocalized = this.updateAgeLocalized.bind(this);
        this.updateCancelCallLocalized = this.updateCancelCallLocalized.bind(this);
        this.updateEndOfCallOldLocalized = this.updateEndOfCallOldLocalized.bind(this);
        this.updateEndOfCallRegularLocalized = this.updateEndOfCallRegularLocalized.bind(this);
        this.updateHintLocalized = this.updateHintLocalized.bind(this);
        this.updateLoadingLocalized = this.updateLoadingLocalized.bind(this);
        this.updateMaskLocalized = this.updateMaskLocalized.bind(this);
        this.updateTutorialLocalized = this.updateTutorialLocalized.bind(this);
        this.updateGlobalLocalized = this.updateGlobalLocalized.bind(this);
        this.updateStartLocalized = this.updateStartLocalized.bind(this);
        this.updateRenderWebContent = this.updateRenderWebContent.bind(this);
        this.updateRenderChatbotContent = this.updateRenderChatbotContent.bind(this);
        this.updateComingSoonLocalized = this.updateComingSoonLocalized.bind(this);
        this.updatePageNotFoundLocalized = this.updatePageNotFoundLocalized.bind(this);
        this.updateChatSteleLocalized = this.updateChatSteleLocalized.bind(this);
        this.updateChatBranches = this.updateChatBranches.bind(this);
        this.updateChatBranchesOver50 = this.updateChatBranchesOver50.bind(this);
        this.updateActualChatThread = this.updateActualChatThread.bind(this);
        this.updateActualChatOver50Thread = this.updateActualChatOver50Thread.bind(this);
        this.updateChatMessageEnlarged = this.updateChatMessageEnlarged.bind(this);
        this.updateButtonsPressed = this.updateButtonsPressed.bind(this);
        this.resetAll = this.resetAll.bind(this);
        this.updateChatProjectionSceneReady = this.updateChatProjectionSceneReady.bind(this);
        this.updateChatBackground = this.updateChatBackground.bind(this);
        this.updateChatEffect = this.updateChatEffect.bind(this);
        this.updateLastPointerInteraction = this.updateLastPointerInteraction.bind(this);
        this.updatePrivacyPolicyLocalized = this.updatePrivacyPolicyLocalized.bind(this);
        this.updateImprintLocalized = this.updateImprintLocalized.bind(this);
        this.updateChatbotLocalized = this.updateChatbotLocalized.bind(this);
        this.updateI18nData = this.updateI18nData.bind(this);
        this.getI18nByKey = this.getI18nByKey.bind(this);
        this.updateIsCancelCallVisible = this.updateIsCancelCallVisible.bind(this);
        this.updateVideoTransitionSourceUrl = this.updateVideoTransitionSourceUrl.bind(this);
        this.updateVideoMainSourceUrl = this.updateVideoMainSourceUrl.bind(this);
        this.updateTransitionFinished = this.updateTransitionFinished.bind(this);
        this.updateChatAiBranches = this.updateChatAiBranches.bind(this);
        this.updateIsLoading = this.updateIsLoading.bind(this);
        this.updateImageProcessingState = this.updateImageProcessingState.bind(this);
        this.updateFaceNotDetected = this.updateFaceNotDetected.bind(this);
        this.updateIsPortrait = this.updateIsPortrait.bind(this);
        this.updateDirectLinkFromChatbot = this.updateDirectLinkFromChatbot.bind(this);
        this.updateIsCameraAllowed = this.updateIsCameraAllowed.bind(this);
    }

    updateLocale(newValue: string, isInitializer?: boolean): void {
        if (isInitializer) {
            trackPage("Loading-Screen", "Start");
        }

        this.locale = newValue;
    }

    updateLocales(newValue: string[]): void {
        this.locales = newValue;
    }

    updateIsCancelCallVisible(newValue: boolean): void {
        this.isCancelCallVisible = newValue;
    }

    updateLocaleNames(newValue: string[]): void {
        this.localeNames = newValue;
    }

    updatePostProcessings(newValue: PostProcessingEntity[]): void {
        this.postProcessings = newValue;
    }

    updateImagePresentUrl(newValue: string | undefined): void {
        this.imagePresentUrl = newValue;
    }

    updateImageAgedUrl(newValue: string | undefined): void {
        this.imageAgedUrl = newValue;
    }

    updateSelfieImageUrl(newValue: string): void {
        this.selfieImageUrl = newValue;
    }

    updateCaptureFace(newValue: boolean): void {
        this.captureFace = newValue;
    }

    updateOver50(newValue: boolean): void {
        this.over50 = newValue;
    }

    updateRenderContent(newValue: UiState): void {
        this.renderContent = newValue;
    }

    updateRenderWebContent(newValue: WebUiState): void {
        this.renderWebContent = newValue;
    }

    updateRenderChatbotContent(newValue: ChatbotUiState): void {
        this.renderChatbotContent = newValue;
    }

    updateButtonById(buttonId: number, pressed: boolean): void {
        if (buttonId >= 500) {
            throw Error("Button Id is out of date");
        }

        this.buttonsPressed[buttonId] = pressed;
    }

    updateChatMessagesScene(newValue: SceneChatMessagesDynamicZone[] | undefined): void {
        this.chatMessagesScene = newValue;
    }

    updateChatOver50MessagesScene(newValue: SceneChatMessagesDynamicZone[] | undefined): void {
        this.chatOver50MessagesScene = newValue;
    }
    getButtonPressedById(buttonId: number): boolean {
        if (buttonId >= 500) {
            throw Error("Button Id is out of date");
        }

        return this.buttonsPressed[buttonId];
    }
    updateChatIndexCurrent(newValue: number): void {
        this.chatIndexCurrent = newValue;
    }
    updateChatOver50IndexCurrent(newValue: number): void {
        this.chatOver50IndexCurrent = newValue;
    }
    updateChatIndexDelayedCurrent(newValue: number | undefined): void {
        this.chatIndexDelayedCurrent = newValue;
    }
    updateChatIndexTypedCurrent(newValue: number | undefined): void {
        this.chatIndexTypedCurrent = newValue;
    }
    updateStartCountdown(newValue: boolean): void {
        this.startCountdown = newValue;
    }
    updateChatTimer(newValue: boolean): void {
        this.chatTimer = newValue;
    }
    updateLandingLocalized(newValue: Landing): void {
        this.landingLocalized = newValue;
    }
    updateMetaLocalized(newValue: Meta): void {
        this.metaLocalized = newValue;
    }
    updateAgeLocalized(newValue: Age): void {
        this.ageLocalized = newValue;
    }
    updateCancelCallLocalized(newValue: CancelCall): void {
        this.cancelCallLocalized = newValue;
    }
    updateEndOfCallOldLocalized(newValue: EndOfCallOld): void {
        this.endOfCallOldLocalized = newValue;
    }
    updateEndOfCallRegularLocalized(newValue: EndOfCallRegular): void {
        this.endOfCallRegularLocalized = newValue;
    }
    updateHintLocalized(newValue: Hint): void {
        this.hintLocalized = newValue;
    }
    updateLoadingLocalized(newValue: Loading): void {
        this.loadingLocalized = newValue;
    }
    updateMaskLocalized(newValue: Mask): void {
        this.maskLocalized = newValue;
    }
    updateTutorialLocalized(newValue: Tutorial): void {
        this.tutorialLocalized = newValue;
    }
    updateGlobalLocalized(newValue: Global): void {
        this.globalLocalized = newValue;
    }
    updateStartLocalized(newValue: Start): void {
        this.startLocalized = newValue;
    }
    updateComingSoonLocalized(newValue: ComingSoon): void {
        this.comingSoonLocalized = newValue;
    }
    updatePageNotFoundLocalized(newValue: PageNotFound): void {
        this.pageNotFoundLocalized = newValue;
    }
    updatePrivacyPolicyLocalized(newValue: PrivacyPolicy): void {
        this.privacyPolicyLocalized = newValue;
    }
    updateImprintLocalized(newValue: Imprint): void {
        this.imprintLocalized = newValue;
    }
    updateChatbotLocalized(newValue: Chatbot): void {
        this.chatbotLocalized = newValue;
    }
    updateChatSteleLocalized(newValue: ChatStele): void {
        this.chatSteleLocalized = newValue;
    }
    updateChatBranches(newValue: ChatBranchEntity[]): void {
        this.chatBranches = newValue;
    }
    updateChatAiBranches(newValue: ChatAiBranchEntity[]): void {
        this.chatAiBranches = newValue;
    }
    updateChatBranchesOver50(newValue: ChatBranchOver50Entity[]): void {
        this.chatBranchesOver50 = newValue;
    }
    updateActualChatThread(
        newValue: (
            | ComponentChatMessageText
            | ComponentChatMessageAiText
            | ComponentChatMessageImage
            | ComponentChatMessageLink
            | ComponentChatMessageButtonGroup
            | ComponentChatMessageGoToBranch
        )[],
    ): void {
        console.log(newValue);
        this.actualChatThread = newValue;
    }
    updateActualChatOver50Thread(
        newValue: (
            | ComponentChatMessageText
            | ComponentChatMessageImage
            | ComponentChatMessageLink
            | ComponentChatMessageButtonGroup
            | ComponentChatMessageGoToBranch
        )[],
    ): void {
        this.actualChatOver50Thread = newValue;
    }
    updateChatMessageEnlarged(newValue: boolean): void {
        this.chatMessageEnlarged = newValue;
    }
    updateButtonsPressed(newValue: boolean[]): void {
        this.buttonsPressed = newValue;
    }
    updateIsLoading(newValue: boolean): void {
        this.isLoading = newValue;
    }

    resetAll(): void {
        this.imageAgedUrl = undefined;
        this.renderContent = UiState.LANDING;
        this.renderWebContent = WebUiState.LANDING;
        this.renderChatbotContent = ChatbotUiState.LANDING;
        this.imagePresentUrl = undefined;
        this.imageAgedUrl = undefined;
        this.captureFace = false;
        this.buttonsPressed = new Array(501).fill(false);
        this.chatMessagesScene = undefined;
        this.chatOver50MessagesScene = undefined;
        this.chatIndexCurrent = 0;
        this.chatOver50IndexCurrent = 0;
        this.chatIndexDelayedCurrent = undefined;
        this.chatOver50IndexDelayedCurrent = undefined;
        this.chatIndexTypedCurrent = undefined;
        this.chatover50indexTypedCurrent = undefined;
        this.renderContent = UiState.LANDING;
        this.actualChatThread = [];
        this.chatMessageEnlarged = false;
        this.chatProjectionSceneReady = false;
        this.chatBackground = "";
        this.chatEffect = "";
        this.actualChatOver50Thread = [];
        this.over50 = false;
        this.chatTimer = false;
        this.videoTransitionSourceUrl = "newValue";
        this.videoMainSourceUrl = "newValue";
        this.transitionFinished = false;
        this.isCancelCallVisible = false;
        this.isLoading = true;
        this.imageProcessingState = {
            positionInQueue: undefined,
            tokenAcquired: false,
            noConnection: false,
            noFaceFound: false,
            waitingToSubmit: false,
            waitingForResult: false,
            processingAborted: false,
            processingComplete: false,
        };
        this.directLinkFromChatbot = false;
    }
    updateChatProjectionSceneReady(newValue: boolean): void {
        this.chatProjectionSceneReady = newValue;
    }
    updateChatBackground(newValue: string): void {
        this.chatBackground = newValue;
    }
    updateChatEffect(newValue: string): void {
        this.chatEffect = newValue;
    }
    updateLastPointerInteraction(): void {
        this.lastPointerInteraction = new Date();
    }
    updateI18nData(newValue: I18NEntity[]): void {
        this.i18nData = newValue;
    }
    getI18nByKey(key: string): I18NEntity | null {
        const found = this.i18nData.filter((el) => el.attributes?.key === key);
        return found[0] ?? null;
    }

    updateVideoTransitionSourceUrl(newValue: string): void {
        this.videoTransitionSourceUrl = newValue;
    }
    updateVideoMainSourceUrl(newValue: string): void {
        this.videoMainSourceUrl = newValue;
    }
    updateTransitionFinished(newValue: boolean): void {
        this.transitionFinished = newValue;
    }
    updateImageProcessingState(newValue: ImageProcessingState): void {
        this.imageProcessingState = newValue;
    }
    updateFaceNotDetected(newValue: boolean): void {
        this.faceNotDetected = newValue;
    }
    updateIsPortrait(newValue: boolean): void {
        this.isPortrait = newValue;
    }
    updateDirectLinkFromChatbot(newValue: boolean): void {
        this.directLinkFromChatbot = newValue;
    }
    updateIsCameraAllowed(newValue: boolean): void {
        this.isCameraAllowed = newValue;
    }
}

const store = new Store();
export default store;
